import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import PeriodTemplateDetails from '../components/PeriodTemplateDetails'

class PeriodTemplate extends React.Component {
  render() {
    const { title } = this.props.data.site.siteMetadata

    //gatsby-node.jsで設定したdisplayMonth, displayYearを読み込みます。
    const { displayMonth, displayYear } = this.props.pageContext

    return (
      <Layout>
        <div>
          <Helmet title={`${displayYear}年${displayMonth}月の記事 - ${title}`} />
          {/* commented out by kinniku <Sidebar {...this.props} /> */}
          <PeriodTemplateDetails {...this.props} />
          <Sidebar {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default PeriodTemplate

export const pageQuery = graphql`
  query PeriodPage($periodStartDate: Date, $periodEndDate: Date) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          twitter
          github
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          date: { gte: $periodStartDate, lt: $periodEndDate }
          layout: { eq: "post" }
          draft: { ne: true }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            tagSlugs
            categorySlug
          }
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            tags
            date
            category
            description
            year: date(formatString: "YYYY")
            month: date(formatString: "MM")
          }
        }
      }
    }
  }
`